/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Footer from '../components/Footer/Footer'
import SEO from '../components/seo'
import DrawerMenu from '../components/DrawerMenu'
import Cart from '../components/Cart'
import Header from '../components/Header'
import PageBuilder from '../components/PageBuilder'

const PageWrap = styled.div`
  ${tw`max-w-full overflow-hidden`}
`

const IndexPage = (props) => {
  const {
    data: { wpPage },
  } = props
  // eslint-disable-next-line camelcase
  const { seo } = wpPage
  const { pageBuilder } = wpPage.pageBuilder

  return (
    <>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={
          seo.opengraphImage
            ? seo.opengraphImage.mediaItemUrl
            : pageBuilder[0].image
            ? pageBuilder[0].image.localFile.childImageSharp.fluid.src
            : ''
        }
        path=""
        imgalt={
          seo.opengraphImage ? seo.opengraphImage.altText : pageBuilder[0].image ? pageBuilder[0].image.altText : ''
        }
      />
      <div className="whiteonblack homeheader">
        <Header />
      </div>
      <DrawerMenu />
      <Cart />
      <PageWrap className="homepage">{pageBuilder ? <PageBuilder data={pageBuilder} /> : null}</PageWrap>
      <Footer />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query GET_INDEX_PAGE {
    wpPage(slug: { eq: "home" }) {
      id
      ...Seo
      pageBuilder {
        pageBuilder {
          ...WpImageBlocks
          ...WpTestimonials
          ...WpLogoWall
          ...WpProducts
        }
      }
    }
  }
`
